/* .container {
    display: inline-block;
    cursor: pointer;
  }
  
  .bar1, .bar2, .bar3 {
    width: 30px;
    height: 2.5px;
    background-color: #fffcfc;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
  }
  
  .change .bar2 {opacity: 0;}
  
  .change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
  }

  .search{
    margin:15px;
    padding: 6px;
  } */

  /* .body {
    font-family: "Lato", sans-serif;
    transition: background-color .5s;
  }
  
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  #main {
    transition: margin-left .5s;
    padding: 16px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  } */

  div.scrollmenu {
    background-color: #333;
    overflow: auto;
    white-space: wrap;
    margin:10px;
  }
  
  div.scrollmenu a {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }
  
  div.scrollmenu a:hover {
    background-color: #777;
  }

  .logo{
    margin:10px;
    height: 70px;
    width: 70px;
  }

  .scrollmenu{
    padding:10px;
  }


